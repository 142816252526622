<template>
  <div class="col-12 py-2 px-4" @click="selectOption(option)">
    <div class="form-check medium">
      <div class="row m-0 align-items-center">
        <div class="col-auto p-0">
          <input class="form-check-input" type="checkbox" :checked="isSelected">
        </div>
        <div class="col-auto p-0">
          <label class="form-check-label">
            {{option[1].name}} <span v-if="option[1].count">({{option[1].count}})</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FilterSelectItem',
  props: ['option', 'isSelected'],
  emits: ['itemClicked'],
  methods: {
    selectOption (val) {
      this.$emit('itemClicked', val[0])
    }
  }
}
</script>
<style scoped>
  .form-check-label {
    white-space: nowrap;
    overflow-wrap: normal;
  }
</style>
